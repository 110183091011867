import { HttpTransportType, HubConnection, HubConnectionState } from '@microsoft/signalr'
import debounce from 'lodash/debounce'
import findIndex from 'lodash/findIndex'
import pull from 'lodash/pull'
import pWaitFor from 'p-wait-for'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'
import { ENV } from '~/configs/ENV'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { eventEmitter } from '~/modules/SDK/Events/eventEmitter'
import { EventString } from '~/modules/SDK/Events/EventString'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { signalrConnectionBuilder } from '~/modules/SDK/Signalr/signalrConnectionBuilder'
import { SignalrTopic } from '~/modules/SDK/Signalr/SignalrTopic'
import { createStore } from '~/store/createStore'

/**
 * Signalr 實體與 React 之黏合容器
 *
 * - 處理 Signalr 自身事件或動作
 * - 處理 Signalr 訂閱列表
 * - 不處理收到的值
 */
export const useSignalrStore = createStore<{
  /** Signalr 單例實體 */
  connection: HubConnection | null
  restart(): Promise<HubConnection>
  start(): Promise<HubConnection>
  stop(): Promise<void>
  /**
   * 增加 symbol 到訂閱列表中
   *
   * 會累積好清單後，再一口氣送 symbols，以避免多組件同時 mount 同時送，浪費資源的情況
   *
   * @example <caption>訂閱選擇權 options</caption>
   *   useMount(() => {
   *     useSignalrStore.getState().subscribeAdd('TXO17300H1')
   *   })
   *
   * @example <caption>訂閱OHLC報價</caption>
   *   useMount(() => {
   *     useSignalrStore.getState().subscribeAdd('TX-1')
   *   })
   *
   * @example <caption>訂閱OHLC報價</caption>
   *   useMount(() => {
   *     useSignalrStore.getState().subscribeAdd('TX-1')
   *   })
   */
  subscribeAdd(symbolsString: Signalr.SymbolString[], type: 'ohlc' | 'bidasktick' | 'options'): void
  /** 增加套餐(topic) 訂閱到訂閱列表中 */
  subscribeAddTopic(topic: SignalrTopic[]): void
  /** 增加套餐(TradeInfoTopic) 訂閱到訂閱列表中 */
  subscribeAddTradeInfoTopic(topic: SignalrTopic.tw_options[]): void
  /** 移除套餐(topic) 訂閱從訂閱列表中 */
  subscribeRemoveTopic(topic: SignalrTopic[]): void
  /**
   * 解除訂閱
   *
   * - 若不主動解除訂閱，但 Signlar 實體主動斷開連線時(stop called)，則所有 symbol 也會自動將會被後端解除訂閱
   * - 適用於需要人工切換訂閱與解除訂閱時（主動優化降低不必要流量）
   */
  subscribeRemove(
    symbolsString: Signalr.SymbolString[],
    type: 'ohlc' | 'bidasktick' | 'options',
  ): void
  /**
   * 儲存訂閱列表
   *
   * - 訂閱列表可能來自異步渲染的各個子組件
   */
  subscribedList: {
    /** 以下 Quote/OHLC 商品被訂閱中 */
    quoteSymbols: Signalr.SymbolString[]
    /** 以下 「五檔掛單與逐筆報價」 商品被訂閱中 */
    bidasktickSymbols: Signalr.SymbolString[]
    /** 「選擇權」所有報價被訂閱中 */
    options: string[]
  }
}>((set, get) => {
  // 多個組件同時觸發 subscribe 但透過 debounce 不要瘋狂送 invoke
  eventEmitter.on(
    EventString.signalrSubscribeAdd,
    debounce(data => {
      const connection = get().connection

      pWaitFor(() => !!connection && connection?.state === HubConnectionState.Connected).then(
        () => {
          assertsSignalrInstanceExists('Signalr.invoke(Subscribe) 失敗。', connection)
          assertsSignalConnected(
            'Signalr.invoke(Subscribe) 失敗。',
            connection?.state === HubConnectionState.Connected,
          )

          const list = get().subscribedList

          debugAPI.signalr.log(`on(EventString.signalrSubscribeAdd) | 送訂閱`, list)

          if (list.quoteSymbols.length) {
            if (list.quoteSymbols.includes('TX-1')) {
              debugAPI.symbol_TX1.log(
                "on(EventString.signalrSubscribeAdd) | 送訂閱 QuoteSymbols: [..., 'TX-1']",
              )
            }
            connection?.invoke(apirc.signalr.method.Subscribe, {
              QuoteSymbols: [...list.quoteSymbols],
            })
          }

          if (list.bidasktickSymbols.length) {
            if (list.quoteSymbols.includes('TX-1')) {
              debugAPI.symbol_TX1.log(
                "on(EventString.signalrSubscribeAdd) | 送訂閱 BidAskSymbols: [..., 'TX-1']",
              )
              debugAPI.symbol_TX1.log(
                "on(EventString.signalrSubscribeAdd) | 送訂閱 TickSymbols: [..., 'TX-1']",
              )
            }
            connection?.invoke(apirc.signalr.method.Subscribe, {
              BidAskSymbols: [...list.bidasktickSymbols],
              TickSymbols: [...list.bidasktickSymbols],
            })
          }

          if (list.options.length) {
            connection?.invoke(apirc.signalr.method.Subscribe, {
              QuoteTopics: [SignalrTopic.tw_options],
            })
          }
        },
      )
    }, 150),
  )

  // Signlar 連線完成後，才能使用 invoke
  eventEmitter.on(EventString.signalrOnConnect, () => {
    const connection = get().connection
    const bidasktickTopics = get().subscribedList.bidasktickSymbols.length
      ? [SignalrTopic.bidask, SignalrTopic.tick]
      : []

    const optionsTopics = get().subscribedList.options.length ? [SignalrTopic.tw_options] : []

    assertsSignalrInstanceExists('on(EventString.signalrOnConnect) 失敗。', connection)
    debugAPI.signalr.log(`on(EventString.signalrOnConnect) | 送訂閱`, get().subscribedList)

    const list = get().subscribedList

    if (list.quoteSymbols.includes('TX-1')) {
      debugAPI.symbol_TX1.log(
        "on(EventString.signalrOnConnect) | 送解訂閱 QuoteSymbols: [..., 'TX-1']",
      )
    }
    if (list.quoteSymbols.includes('TX-1')) {
      debugAPI.symbol_TX1.log(
        "on(EventString.signalrOnConnect) | 送解訂閱 BidAskSymbols: [..., 'TX-1']",
      )
      debugAPI.symbol_TX1.log(
        "on(EventString.signalrOnConnect) | 送解訂閱 TickSymbols: [..., 'TX-1']",
      )
    }

    connection?.invoke(apirc.signalr.method.Subscribe, {
      QuoteTopics: [...bidasktickTopics, ...optionsTopics],
      QuoteSymbols: [...list.quoteSymbols],
      BidAskSymbols: [...list.bidasktickSymbols],
      TickSymbols: [...list.bidasktickSymbols],
    })
  })

  return {
    connection: null,
    async restart() {
      debugAPI.signalr.log('restart()')

      await this.stop()
      return await this.start()
    },
    async start() {
      debugAPI.signalr.log('start()')
      const connection = get().connection
      const isConnected = connection?.state === HubConnectionState.Connected

      // 如果已連線
      if (connection && isConnected) {
        eventEmitter.emit(EventString.signalrOnConnect)
        return connection
      }

      // 如果未曾連線
      set(state => {
        state.connection = signalrConnectionBuilder
          .withUrl(
            urlcat(apirc.signalr.wsURL.baseUrl, {
              uid: useMeStore.getState().meUserState?.uid || '__UNSET__',
              commithash: ENV.COMMITHASH,
            }),
            {
              skipNegotiation: true,
              transport: HttpTransportType.WebSockets,
            },
          )
          .build()
      })

      await get().connection?.start()

      return await pWaitFor(
        () => !!get().connection && get().connection?.state === HubConnectionState.Connected,
        { interval: 100, timeout: 10000 },
      ).then(() => {
        debugAPI.signalr.log('start().then(已連線)')
        const instance = get().connection

        if (!instance) {
          console.error(
            `Expect Signalr instance to be exists, but got ${typeof instance}`,
            instance,
          )
          throw new Error(`Unexpected, the signalr instance not found`)
        }

        eventEmitter.emit(EventString.signalrOnConnect)

        return instance
      })
    },
    async stop() {
      debugAPI.signalr.log('stop()')
      const connection = get().connection

      if (!connection) {
        return
      }

      await connection?.stop()

      set(state => {
        state.connection = null
      })

      return pWaitFor(() => connection?.state === HubConnectionState.Disconnected, {
        interval: 100,
        timeout: 10000,
      }).then(() => {
        debugAPI.signalr.log('stop().then(已中斷)')
      })
    },
    subscribeAddTopic(topics) {
      pWaitFor(() => get().connection?.state === HubConnectionState.Connected, {
        interval: 1000,
        timeout: 15000,
      })
        .then(() => {
          const connection = get().connection
          assertsSignalrInstanceExists('store.subscribeTopic() 失敗。', connection)

          if (connection?.state === HubConnectionState.Connected) {
            connection?.invoke(apirc.signalr.method.Subscribe, {
              QuoteTopics: topics,
            })
          } else {
            this.subscribeAddTopic(topics)
          }
        })
        .catch(() => {
          // didn't care error
        })
    },
    subscribeAddTradeInfoTopic(topics) {
      debugAPI.signalr.log(`[${this.subscribeAddTradeInfoTopic.name}] 等待連線`)
      pWaitFor(() => get().connection?.state === HubConnectionState.Connected, {
        interval: 1000,
        timeout: 15000,
      })
        .then(() => {
          const connection = get().connection
          assertsSignalrInstanceExists('store.subscribeAddTradeInfoTopic() 失敗。', connection)

          debugAPI.signalr.log(`[${this.subscribeAddTradeInfoTopic.name}] 送訂閱`, {
            invoke: apirc.signalr.method.Subscribe,
            TradeInfoTopics: topics,
          })

          return connection?.invoke(apirc.signalr.method.Subscribe, {
            TradeInfoTopics: topics,
          })
        })
        .catch(error => {
          debugAPI.signalr.log(`[${this.subscribeAddTradeInfoTopic.name}] 錯誤：${error?.message}`)
          // didn't care error
        })
    },
    subscribeRemoveTopic(topics) {
      pWaitFor(() => get().connection?.state === HubConnectionState.Connected, {
        interval: 1000,
        timeout: 15000,
      })
        .then(() => {
          const connection = get().connection
          assertsSignalrInstanceExists('store.subscribeTopic() 失敗。', connection)

          if (connection?.state === HubConnectionState.Connected) {
            connection?.invoke(apirc.signalr.method.Unsubscribe, {
              QuoteTopics: topics,
            })
          } else {
            this.subscribeRemoveTopic(topics)
          }
        })
        .catch(() => {
          // didn't care error
        })
    },
    subscribeAdd(symbolsString, type) {
      const quoteSymbols_ = (symbolsString || []).filter(symbol => !!symbol) || []

      set(state => {
        quoteSymbols_.forEach(symbolstring => {
          if (
            symbolstring.startsWith('TXO') ||
            symbolstring.startsWith('TX1') ||
            symbolstring.startsWith('TX2') ||
            symbolstring.startsWith('TX4') ||
            symbolstring.startsWith('TX5') ||
            type === 'options'
          ) {
            state.subscribedList.options.push(symbolstring)
          } else if (type === 'bidasktick') {
            state.subscribedList.bidasktickSymbols.push(symbolstring)
          } else if (type === 'ohlc') {
            state.subscribedList.quoteSymbols.push(symbolstring)
          }
        })
      })

      eventEmitter.emit(EventString.signalrSubscribeAdd)
    },
    subscribeRemove(symbolsString, type) {
      const quoteSymbols_ = (symbolsString || []).filter(symbol => !!symbol) || []
      const unQuoteSymbols: string[] = []
      const unBidasktickSymbols: string[] = []
      const unOptionsSymbols: string[] = []

      set(state => {
        quoteSymbols_.forEach((symbolstring, index) => {
          if (
            symbolstring.startsWith('TXO') ||
            symbolstring.startsWith('TX1') ||
            symbolstring.startsWith('TX2') ||
            symbolstring.startsWith('TX4') ||
            symbolstring.startsWith('TX5') ||
            type === 'options'
          ) {
            const indexToRemove = findIndex(
              state.subscribedList.options,
              symbol => symbol === symbolstring,
            )
            state.subscribedList.options.splice(indexToRemove, 1)
            unOptionsSymbols.push(symbolstring)
          } else if (type === 'bidasktick') {
            const indexToRemove = findIndex(
              state.subscribedList.bidasktickSymbols,
              symbol => symbol === symbolstring,
            )
            state.subscribedList.bidasktickSymbols.splice(indexToRemove, 1)
            unBidasktickSymbols.push(symbolstring)
          } else if (type === 'ohlc') {
            const indexToRemove = findIndex(
              state.subscribedList.quoteSymbols,
              symbol => symbol === symbolstring,
            )
            state.subscribedList.quoteSymbols.splice(indexToRemove, 1)
            unQuoteSymbols.push(symbolstring)
          }
        })
      })
      const connection = get().connection
      const list = get().subscribedList

      assertsSignalrInstanceExists('store.subscribeRemove() 失敗。', connection)

      for (const symbolToUnsub of unQuoteSymbols) {
        if (type === 'ohlc' && list.quoteSymbols.includes(symbolToUnsub)) {
          pull(unQuoteSymbols, symbolToUnsub)
        }
        if (type === 'options' && list.options.includes(symbolToUnsub)) {
          pull(unQuoteSymbols, symbolToUnsub)
        }
        if (type === 'bidasktick' && list.bidasktickSymbols.includes(symbolToUnsub)) {
          pull(unQuoteSymbols, symbolToUnsub)
        }
      }

      debugAPI.signalr.log(`signalrStore.subscribeRemove(${type}) | 送解訂閱`, unQuoteSymbols)

      if (connection?.state === HubConnectionState.Connected) {
        if (unQuoteSymbols.length) {
          if (unQuoteSymbols.includes('TX-1')) {
            debugAPI.symbol_TX1.log(
              `signalrStore.subscribeRemove(${type}) | 送解訂閱`,
              unQuoteSymbols,
            )
          }

          connection?.invoke(apirc.signalr.method.Unsubscribe, {
            QuoteSymbols: [...unQuoteSymbols],
          })
        }

        if (unBidasktickSymbols.length) {
          if (unQuoteSymbols.includes('TX-1')) {
            debugAPI.symbol_TX1.log(
              `signalrStore.subscribeRemove(${type}) | 送解訂閱`,
              unBidasktickSymbols,
            )
          }

          connection?.invoke(apirc.signalr.method.Unsubscribe, {
            QuoteTopics: !list.bidasktickSymbols.length
              ? [SignalrTopic.bidask, SignalrTopic.tick]
              : undefined,
            TickSymbols: [...unBidasktickSymbols],
            BidAskSymbols: [...unBidasktickSymbols],
          })
        }

        if (unOptionsSymbols.length && !list.options.length) {
          connection?.invoke(apirc.signalr.method.Unsubscribe, {
            QuoteTopics: [SignalrTopic.tw_options],
          })
        }
      }
    },
    subscribedList: {
      quoteSymbols: [],
      bidasktickSymbols: [],
      options: [],
    },
  }
})

type OHLCState = Partial<{
  [symbolString in Signalr.SymbolString | string]: Signalr.ValueOfOHLC
}>

function assertsSignalrInstanceExists<T extends null | undefined | HubConnection>(
  msg: string,
  signalrConnectionInstance: T,
): boolean {
  if (!signalrConnectionInstance) {
    debugAPI.signalr.log(`${msg}；還未建立 Signalr 實體。`)
    return false
  }

  return true
}

function assertsSignalConnected<T extends null | undefined | boolean>(
  msg: string,
  value: T,
): asserts value is T {
  if (value !== true) {
    debugAPI.signalr.throwErrorAndLog(`${msg}；還未建立 Signalr 連線。`)
  }
}
