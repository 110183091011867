import { HttpTransportType, HubConnection, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { debounce, findIndex, max } from 'lodash'
import pWaitFor from 'p-wait-for'
import urlcat from 'urlcat'
import { proxy, ref, subscribe } from 'valtio'
import { apirc } from '~/configs/apirc'
import { ENV } from '~/configs/ENV'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { signalrConnectionBuilder } from '~/modules/SDK/Signalr/signalrConnectionBuilder'
import { SignalrEvent } from '~/modules/SDK/Signalr/SignalrEvent'
import { SignalrMethod } from '~/modules/SDK/Signalr/SignalrMethod'
import { SignalrTopic } from '~/modules/SDK/Signalr/SignalrTopic'
import { fr_events } from '~/pages/heineken_template/_fr/fr_events'
import { __DEV__ } from '~/utils/__DEV__'

/**
 * # signalr/core@2
 *
 * - Proxy 版本的 {@link useSignalrStore} 重製
 * - 取代 {@link useSignalrStore} 和 一堆的 {@link useSignalrStoreValueOHLC}
 */
export const signalrStore2 = proxy(
  new (class {
    /** # 唯一的 Signalr 實體(instance) */
    connection: HubConnection

    /**
     * # 前端訂閱清單
     *
     * ### 若有重複的 string 在列表中，則代表「不只一個組件發出訂閱請求」
     *
     * 例如若值為以下
     *
     *     QuoteSymbols: ['TX-1', 'TX-1', 'GC-1']
     *
     * 則代表有兩個以上的組件皆訂閱 `'TX-1'` 這個 symbol
     *
     * 當以上的「其中一個組件」unsub `'TX-1'` 時
     *
     * 其值應為
     *
     *     QuoteSymbols: ['TX-1', 'GC-1']
     *
     * 代表意義：只 unmount/unsub 其中一個組件報價，另一個組件則繼續訂閱
     */
    readonly subscriptions = proxy({
      QuoteSymbols: [] as string[],
      BidAskSymbols: [] as string[],
      QuoteTopics: [] as string[],
      TradeInfoTopics: [] as string[],
      TickSymbols: [] as string[],
    })

    /** # 後端推送值 */
    readonly values = {
      quote: {} as Partial<Record<string, Signalr.ValueOfOHLC>>,
      bidask: {} as Partial<Record<string, Signalr.ValueOfBidAsk>>,
      tradeInfo: {} as Partial<Record<string, Signalr.ValueOfTradeInfo>>,
      tick: {} as Partial<Record<string, Signalr.ValueOfTick>>,
    }

    constructor() {
      debugAPI.signalrV2.log(`建立單例實體`)
      this.connection = ref(
        signalrConnectionBuilder
          .withUrl(
            urlcat(apirc.signalr.wsURL.baseUrl, {
              version: 'signalrStore2',
              commithash: ENV.COMMITHASH,
            }),
            {
              skipNegotiation: true,
              transport: HttpTransportType.WebSockets,
              logger: LogLevel.Information,
            },
          )
          .build(),
      )

      // handle 所有後端推送值
      this.connection.on(SignalrEvent.Quote, this.handleQuoteUpdate)
      this.connection.on(SignalrEvent.BidAsk, this.handleBidaskUpdate)
      this.connection.on(SignalrEvent.TradeInfo, this.handleTradeInfoUpdate)
      this.connection.on(SignalrEvent.Tick, this.handleTickUpdate)

      this.connection.onclose(error => {
        debugAPI.signalrV2.log(`已斷開連線`, { error })
      })

      // log
      this.connection.invoke = new Proxy(this.connection.invoke, {
        apply(target, connection, args) {
          debugAPI.signalrV2.log(`呼叫後端 invoke()`, ...args)
          return Reflect.apply(target, connection, args)
        },
      })
    }

    get isConnecting() {
      return signalrStore2.connection.state === HubConnectionState.Connecting
    }

    get isConnected() {
      return signalrStore2.connection.state === HubConnectionState.Connected
    }

    get isDisconnected() {
      return signalrStore2.connection.state === HubConnectionState.Disconnected
    }

    get isDisconnecting() {
      return signalrStore2.connection.state === HubConnectionState.Disconnecting
    }

    get isReconnecting() {
      return signalrStore2.connection.state === HubConnectionState.Reconnecting
    }

    /** # 開始連線 */
    async start() {
      const log = debugAPI.signalrV2.logger.extend(`${signalrStore2.start.name}()`)

      log(`正在建立連線`)

      try {
        await signalrStore2.connection.start()

        log(`已建立連線`)
      } catch (error: any) {
        throw new Error(`無法建立連線`, { cause: error })
      }
    }

    async stop() {
      await signalrStore2.connection.stop()
    }

    handleQuoteUpdate(data: Signalr.ValueOfOHLC) {
      signalrStore2.values.quote[data.symbol] = data
    }

    handleBidaskUpdate(data: Signalr.ValueOfBidAsk) {
      const totalAskQty = data.askQty1 + data.askQty2 + data.askQty3 + data.askQty4 + data.askQty5
      const totalBidQty = data.bidQty1 + data.bidQty2 + data.bidQty3 + data.bidQty4 + data.bidQty5

      signalrStore2.values.bidask[data.symbol] = {
        ...data,
        totalQty: totalAskQty + totalBidQty,
        totalAskQty,
        totalBidQty,
        maxQty:
          max([
            data.askQty1,
            data.askQty2,
            data.askQty3,
            data.askQty4,
            data.askQty5,
            data.bidQty1,
            data.bidQty2,
            data.bidQty3,
            data.bidQty4,
            data.bidQty5,
          ]) ?? 0,
      }
    }

    handleTradeInfoUpdate(data: Signalr.ValueOfTradeInfo) {
      signalrStore2.values.tradeInfo[data.symbol] = data
    }

    handleTickUpdate(data: Signalr.ValueOfTick) {
      signalrStore2.values.tick[data.symbol] = data
    }

    /** 送出「前端維護的訂閱清單」給後端知道 */
    async invokeSubscriptions() {
      await signalrStore2.connection.invoke(SignalrMethod.Subscribe, {
        ...signalrStore2.subscriptions,
      })
    }

    async addQuote(symbols: undefined | string | string[]) {
      const $symbols = ensureAsArray(symbols)

      signalrStore2.subscriptions.QuoteSymbols = [
        ...signalrStore2.subscriptions.QuoteSymbols,
        ...$symbols,
      ]
    }

    async removeQuote(symbols: undefined | string | string[]) {
      const $symbols = ensureAsArray(symbols)

      removeRefSymbols(signalrStore2.subscriptions.QuoteSymbols, $symbols)
      await invokeSymbols(signalrStore2.subscriptions.QuoteSymbols, $symbols, 'QuoteSymbols')
    }

    async removeAllQuotes() {
      await signalrStore2.connection.invoke(SignalrMethod.Unsubscribe, {
        QuoteSymbols: signalrStore2.subscriptions.QuoteSymbols,
      })
      signalrStore2.subscriptions.QuoteSymbols = []
    }

    async addQuoteTopic(symbols: undefined | SignalrTopic | SignalrTopic[]) {
      const $symbols = ensureAsArray(symbols)

      signalrStore2.subscriptions.QuoteTopics = [
        ...signalrStore2.subscriptions.QuoteTopics,
        ...$symbols,
      ]
    }

    async removeQuoteTopic(symbols: undefined | SignalrTopic | SignalrTopic[]) {
      const $symbols = ensureAsArray(symbols)

      removeRefSymbols(signalrStore2.subscriptions.QuoteTopics, $symbols)
      await invokeSymbols(signalrStore2.subscriptions.QuoteTopics, $symbols, 'QuoteTopics')
    }

    async addBidask(symbols: undefined | string | string[]) {
      const $symbols = ensureAsArray(symbols)

      signalrStore2.subscriptions.BidAskSymbols = [
        ...signalrStore2.subscriptions.BidAskSymbols,
        ...$symbols,
      ]
    }

    async removeBidask(symbols: undefined | string | string[]) {
      const $symbols = ensureAsArray(symbols)

      removeRefSymbols(signalrStore2.subscriptions.BidAskSymbols, $symbols)
      await invokeSymbols(signalrStore2.subscriptions.BidAskSymbols, $symbols, 'BidAskSymbols')
    }

    async addTradeInfoTopic(symbols: undefined | SignalrTopic | SignalrTopic[]) {
      const $symbols = ensureAsArray(symbols)

      signalrStore2.subscriptions.TradeInfoTopics = [
        ...signalrStore2.subscriptions.TradeInfoTopics,
        ...$symbols,
      ]
    }

    async removeTradeInfoTopic(symbols: undefined | SignalrTopic | SignalrTopic[]) {
      const $symbols = ensureAsArray(symbols)

      removeRefSymbols(signalrStore2.subscriptions.TradeInfoTopics, $symbols)
      await invokeSymbols(signalrStore2.subscriptions.TradeInfoTopics, $symbols, 'TradeInfoTopics')
    }

    async addTick(symbols: undefined | string | string[]) {
      const $symbols = ensureAsArray(symbols)

      signalrStore2.subscriptions.TickSymbols = [
        ...signalrStore2.subscriptions.TickSymbols,
        ...$symbols,
      ]
    }

    async removeTick(symbols: undefined | string | string[]) {
      const $symbols = ensureAsArray(symbols)

      removeRefSymbols(signalrStore2.subscriptions.TickSymbols, $symbols)
      await invokeSymbols(signalrStore2.subscriptions.TickSymbols, $symbols, 'TickSymbols')
    }
  })(),
)

const ensureAsArray = (symbols: undefined | string | string[]): string[] =>
  !symbols ? [] : Array.isArray(symbols) ? symbols : [symbols]

const removeRefSymbols = (symbols: string[], removing: string[]) => {
  for (const $removing of removing) {
    symbols.splice(
      findIndex(symbols, symbol => symbol === $removing),
      1,
    )
  }
}

const invokeSymbols = async (
  symbols: string[],
  removing: string[],
  target: keyof typeof signalrStore2.subscriptions,
) => {
  for (const $removing of removing) {
    if (!symbols.includes($removing)) {
      await signalrStore2.connection.invoke(SignalrMethod.Unsubscribe, {
        [target]: [$removing],
      })
    }
  }
}
