/* eslint-disable react/display-name */
import { css } from '@emotion/react'
import MenuIcon from '@mui/icons-material/Menu'
import { Fragment, memo } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { globalBlueGreen } from '~/modules/AppLayout/Colors'
import { ref } from 'valtio'
import { Drawer, DrawerProps } from '@mui/material'
import useMedia from '~/hooks/useMedia'

export class FuiDrawer {
  store: {
    open: boolean
    position: DrawerProps['anchor']
  }

  constructor(initialState?: Partial<FuiDrawer['store']>) {
    this.store = proxy({
      open: false,
      position: 'left',
      ...initialState,
    })

    this.Drawer.displayName = `${FuiDrawer.name}.Drawer`
    this.DrawerToggle.displayName = `${FuiDrawer.name}.DrawerToggle`
    this.RenderProps.displayName = `${FuiDrawer.name}.RenderProps`
  }

  open() {
    this.store.open = true
  }

  close() {
    this.store.open = false
  }

  toggle() {
    this.store.open = !this.store.open
  }

  RenderProps = ref(
    memo<RenderProps<FuiDrawer['store']>>(props => {
      const state = useSnapshot(this.store)

      return <Fragment>{props.children(state)}</Fragment>
    }),
  )

  Drawer = ref(
    memo<ReactProps>(props => {
      const state = useSnapshot(this.store)

      return (
        <Drawer
          className={props.className}
          anchor={state.position}
          open={state.open}
          onClose={() => {
            this.close()
          }}
          ModalProps={{ disablePortal: true }}
        >
          {props.children}
        </Drawer>
      )
    }),
  )

  DrawerToggle = ref(
    memo<ReactProps>(props => {
      const { isPc } = useMedia()

      if (isPc) {
        return null
      }

      return (
        <MenuIcon
          css={css`
            width: 36px;
            height: 36px;
            color: ${globalBlueGreen.bg500};
          `}
          className={props.className}
          onClick={event => {
            this.toggle()
          }}
        />
      )
    }),
  )
}
