export enum SignalrTopic {
  'tw_futures' = 'tw_futures',
  'tw_options' = 'tw_options',
  'tw_stocks_50' = 'tw_stocks_50',
  'tw_stocks_all' = 'tw_stocks_all',
  'os_futures' = 'os_futures',
  /** 五檔掛單報價 */
  'bidask' = 'bidask',
  /** 逐筆成交 */
  'tick' = 'tick',
}
