import camelcaseKeys from 'camelcase-keys'
import { useInterval } from 'react-use'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { UseSignalrBuildParams } from '~/modules/SDK/Signalr/useSignalrBuild'
import { createStore } from '~/store/createStore'

/**
 * Signalr 訂閱連線之中，用來儲存 OHLC 格式的 Store
 *
 * - 和 topic:bidask 以及 topic:tick 格式不相容
 */
export const useSignalrStoreValueOHLC = createStore<{
  value: Partial<{
    [symbolString: string]: Signalr.ValueOfOHLC
  }>
  useGroupOHLC(symbols: Signalr.SymbolString[] | undefined): (Signalr.ValueOfOHLC | undefined)[]
  valueBuilder: UseSignalrBuildParams['ValueBuilder']
  /**
   * 替換內建 interval render
   *
   * - 內建 interval 500ms
   */
  useValueUpdateInterval(intervalMillisecond: number): void
}>((set, get) => {
  const value_: Partial<{
    [symbolString: string]: Signalr.ValueOfOHLC
  }> = {}

  // defaults interval function for  value updating by interval
  const updateIntervalDefaults = setInterval(() => {
    set(state => {
      state.value = { ...value_ }
    })
  }, 500)

  // initial value display ASAP
  setTimeout(() => {
    set(state => {
      state.value = { ...value_ }
    })
  }, 500)

  return {
    value: {},
    useGroupOHLC: (symbols: Signalr.SymbolString[]) => {
      const a = symbols.map(s => get().value[s])
      return a
    },
    valueBuilder: data => {
      if ('symbol' in data && 'close' in data && 'open' in data) {
        const data_ = camelcaseKeys(data)

        value_[data_.symbol] = data_
      }
    },
    useValueUpdateInterval(intervalMillisecond) {
      clearInterval(updateIntervalDefaults)

      useInterval(() => {
        set(state => {
          state.value = { ...value_ }
        })
      }, intervalMillisecond)
    },
  }
})
